
import Stomp from 'stompjs';
import store from '../store';
import { getToken } from './auth'
import moment from 'moment'
import 'moment-timezone';
import { Locale } from 'vant';
import zhCN from 'vant/es/locale/lang/zh-CN';
import zhTW from 'vant/es/locale/lang/zh-TW';
import enUS from 'vant/es/locale/lang/en-US';
import esES from 'vant/es/locale/lang/es-ES';
import frFR from 'vant/es/locale/lang/fr-FR';
import jaJP from 'vant/es/locale/lang/ja-JP';
import thTH from 'vant/es/locale/lang/th-TH';
import deDE from 'vant/es/locale/lang/de-DE';
import i18n from "@/i18n";

export function initWebSocket(id, websocketonmessage) {
  let socketUrl = '';
  if (process.env.NODE_ENV == 'development') {
    socketUrl = process.env.VUE_APP_WEBSOCKET_URL;
  } else {
    let host = window.location.host;
    let href = window.location.href;
    socketUrl = (href.indexOf('https:') == 0 ? 'wss://' : 'ws://') + host + '/ws';
  }

  // socketUrl = 'ws://172.104.108.97:7402/ws';  // 测试地址
  return new Promise((resolve) => {
    // const socket = new SockJS(socketUrl); // 使用 SockJS 创建 WebSocket 连接
    // const client = Stomp.over(socket); // 使用 Stomp.over 代替 Stomp.client
    store.state.client = Stomp.client(socketUrl);

    store.state.client.reconnectDelay = 5000;
    store.state.client.heartbeatIncoming = 4000;
    store.state.client.heartbeatOutgoing = 4000;

    store.state.client.connect({
      host: "/lucky8",
      debug: false
    }, function (frame) {
      // 发送消息
      let message = {
        ReceiverID: 0,
        Type: 0,
        Content: "history",
        token: getToken()
      }
      store.state.client.send("/exchange/direct_logs/chat_queue", { "content-type": "application/json" }, JSON.stringify(message));

      // 订阅消息队列
      store.state.client.subscribe(`/exchange/direct_logs`, function (message) {
        // 处理收到的消息
        if (websocketonmessage) {
          websocketonmessage(message);
        }
      });
    }, function (error) {
      console.error("Connection error: " + error);
      // 100ms后重新连接
        setTimeout(() => {
            initWebSocket(id, websocketonmessage);
        }, 100);
    });

    store.state.client.onStompError = function (frame) {
      console.error("Broker reported error: " + frame.headers['message']);
      console.error("Additional details: " + frame.body);
    };
    // 暂存client对象以便后续操作（如断开连接）
  });
}


// 数字格式化
export function formatNumber(num) {
  return parseFloat(num).toFixed(4)
  if (num.toString().indexOf('.') !== -1) {
    const arr = num.toString().split('.');
    if (/^0+$/.test(arr[1])) {
      return arr[0] + '.00';
    } else if (arr[1].length === 1) {
      return arr[0] + '.' + arr[1] + '0';
    } else if (arr[1].length === 3) {
      return arr[0] + '.' + arr[1] + '00';
    } else if (arr[1].length > 4) {
      return arr[0] + '.' + arr[1].slice(0, 4);
    } else {
      return arr[0] + '.' + arr[1].padEnd(4, '0');
    }
  } else {
    return num + '.00';
  }
}

// 默认语言
export function getDefaultLanguage () {
  const defaultLanguage = store.state.language;
  const language = navigator.language
  const clientLanguage = (language != 'zh-CN' && language != 'zh-TW') ? language.substring(0, 2) : language
  const messages = [
    'zh-CN',
    'zh-TW',
    'ar',
    'en',
    'es',
    'fr',
    'id',
    'ja',
    'ko',
    'pt',
    'ru',
    'th',
    'he',
    'tr',
    'hi',
    'vi',
    'de',
    'it',
    'kk',
    'ms'
  ]
  const local = messages.indexOf(clientLanguage) > -1 ? clientLanguage : defaultLanguage
  return local
}

export  function formatTime(inputTime) {
  const dateObject = new Date(inputTime);

  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, '0');
  const day = String(dateObject.getDate()).padStart(2, '0');
  const hours = String(dateObject.getHours()).padStart(2, '0');
  const minutes = String(dateObject.getMinutes()).padStart(2, '0');
  const seconds = String(dateObject.getSeconds()).padStart(2, '0');

  const newTimeFormat = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;

  return newTimeFormat;
}

export function setCalanderLang(lang, i18n) {
  let keyObj = {
    'zh-CN': 'zh-CN',
    'zh-TW': 'zh-TW',
    'ar': 'en-US', // vant缺失语言包 阿拉伯语
    'en': 'en-US',
    'es': 'es-ES', //西班牙语
    'fr': 'fr-FR', // 法语
    'id': 'en-US', // vant缺失语言包 印尼语
    'ja': 'ja-JP', // 日语
    'ko': 'en-US', // vant缺失语言包 韩语
    'pt': 'en-US', // vant缺失语言包 葡萄牙语
    'ru': 'en-US', // vant缺失语言包 俄语
    'th': 'th-TH', // 泰语
    'he': 'en-US', // vant缺失语言包 希伯来语
    'tr': 'en-US', // vant缺失语言包 土耳其语
    'hi': 'en-US', // vant缺失语言包 印地语
    'vi': 'en-US', // vant缺失语言包 越南语
    'de': 'de-DE', // 德语
    'it': 'en-US', // vant缺失语言包 意大利语
    'kk': 'en-US', // vant缺失语言包 哈萨克斯坦语
    'ms': 'en-US', // vant缺失语言包 马来语
  }

  let langObj = {
    'zh-CN': zhCN,
    'zh-TW': zhTW,
    'en-US': enUS,
    'es-ES': esES,
    'fr-FR': frFR,
    'ja-JP': jaJP,
    'th-TH': thTH,
    'de-DE': deDE,
  }
  let newKey = keyObj[lang]
  let newLang = langObj[newKey]

  let weekdays = []
  switch(lang) {
    case 'ar': weekdays = ['الأحد', 'الاثنين', 'الثلاثاء', 'الأربعاء', 'الخميس', 'الجمعة', 'السبت']; break; //阿拉伯语
    case 'id': weekdays = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu']; break; //印尼语
    case 'ko': weekdays = ['일', '월', '화', '수', '목', '금', '토']; break; //韩语
    case 'pt': weekdays = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']; break; //葡萄牙语
    case 'ru': weekdays = ['Вск', 'Сбт', 'Птн', 'Чтв', 'Срд', 'Втр', 'Пнд']; break; //俄语
    case 'he': weekdays = ['יום ראשון', 'יום שני', 'יום שלישי', 'יום רביעי', 'יום חמישי', 'יום שישי', 'יום שבת']; break; //希伯来语
    case 'tr': weekdays = ['Paz', 'Pts', 'Sal', 'Çar', 'Per', 'Cum', 'Cts']; break; // 土耳其语
    case 'hi': weekdays = ['रविवार', 'सोमवार', 'मंगलवार', 'बुधवार', 'गुरूवार', 'शुक्रवार', 'शनिवार']; break; //印地语
    case 'vi': weekdays = ['Chủ nhật', 'Thứ hai', 'Thứ ba', 'Thứ tư', 'Thứ năm', 'Thứ sáu', 'Thứ bảy']; break; //越南语
    case 'it': weekdays = ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab']; break; // 意大利语
    case 'kk': weekdays = ['Жс', 'Дс', 'Сс', 'Ср', 'Бс', 'Жм', 'Сн']; break; //哈萨克斯坦语
    case 'ms': weekdays = ['Ahad', 'Isnin', 'Selasa', 'Rabu', 'Khamis', 'Jumaat', 'Sabtu']; break; // 马来语
    case 'en': weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']; break; //英语，防止被修改成其他语言
    default: break
  }


  Locale.use(newKey, newLang);
  const messages = {
    'en-US': {
      vanCalendar: {
        weekdays: weekdays
      },
    },
  }
  Locale.add(messages);
}

export function resetPrice(price) {
  if (!price || isNaN(price)) {
    price = 0
  }
  let rate = store.state.moneyRate
  let symbol = store.state.moneySymbol
  price = parseFloat(price)
  return `${symbol}${parseFloat(price * rate).toFixed(2)}`
}

export function getTimeStr(time) {
  let start = new Date(time).getTime()
  let end = new Date().getTime()
  let space = (end - start) / 3600 / 1000 / 24
  let str = ''
  if (space < 1) {
    str = i18n.t('jin-tian')
  } else if (space >= 1 && space < 2) {
    str =  i18n.t('zuotian')
  } else if (space >= 2 && space < 3) {
    str = i18n.t('qiantian')
  } else if (space >= 3) {
    str = parseInt(space) + i18n.t('tianqian')
  }
  return str
}

export function syncTime(time) {
  // 从本地存储中获取服务器的时区信息
  let serverTimeZone = window.localStorage.getItem("time_zone");

  // 如果未能获取到时区信息，则默认使用 "Asia/Shanghai"
  serverTimeZone = serverTimeZone || "Asia/Shanghai";

  // 使用 moment.tz 方法将服务器时间转换为本地时间
  const localTime = moment.tz(time, serverTimeZone).toDate();
  return localTime;
}
// 使用dayjs格式化时间
export function formatTimeByDayjs(time, format) {
  if(format === undefined) {
    format = 'YYYY-MM-DD HH:mm:ss'
  }
  return moment(syncTime(time)).format(format)
}


export function getDownTime(start, end) {

  // 转换服务器时间为本地时间
  let startTime = syncTime(start).getTime();
  let endTime = syncTime(end).getTime();

  let now = new Date().getTime()
  let downTime = parseInt((endTime - now) / 1000)
  if (startTime > now) {
    return i18n.t('wei-kai-shi')
  } else if (downTime > 0) {
    let str = ''
    let day = parseInt(downTime / 3600 / 24)
    let h = parseInt((downTime - day * 3600 * 24) / 3600)
    let m = parseInt((downTime - day * 3600 * 24 - h * 3600) / 60)
    let s = downTime % 60
    str = `${day > 0 ? day : ''}${day > 0 ? `${i18n.t('tian')} ` : ''}${h > 9 ? h : '0' + h}:${m > 9 ? m : '0' + m}:${s > 9 ? s : '0' + s}`
    return str
  } else {
    return i18n.t('yi-jie-shu')
  }
}

export function getStartTime(start) {
  let startTime = syncTime(start).getTime();
  let now = new Date().getTime()
  let downTime = parseInt((startTime - now) / 1000)
  if (downTime > 0) {
    let str = ''
    let day = parseInt(downTime / 3600 / 24)
    let h = parseInt((downTime - day * 3600 * 24) / 3600)
    let m = parseInt((downTime - day * 3600 * 24 - h * 3600) / 60)
    let s = downTime % 60
    str = `${day > 0 ? day : ''}${day > 0 ? `${i18n.t('tian')} ` : ''}${h > 9 ? h : '0' + h}:${m > 9 ? m : '0' + m}:${s > 9 ? s : '0' + s}`
    return str
  } else {
    return i18n.t('yi-kai-shi')
  }
}