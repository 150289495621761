<template>
  <div class=" chat-page" id="messageBox">
    <top-bar :title="title" :isWhite="true" @back="back"></top-bar>

    <div class="message-box" >
      <div class="list " v-for="(item, i) in messageArr" :key="i" :class="item.SenderID == userId ? 'flex-start-end right' : 'flex-start'">
        <div class="header" v-if="item.SenderID != userId">
          <img :src="kefuUrl" alt="">
        </div>
        <div class="content-box">
          <div class="name" :class="[item.SenderID == 0 ? 'red' : '', item.SenderID == userId ? 'text-right' : '']">
            {{item.SenderID == 0 ? $t('bo-bao-yuan') : item.SenderName}}
            {{item.CreatedAt ? item.CreatedAt.substring(11,16) : ''}}
          </div>
          <div class="content" style="word-wrap:break-word" @click="showSingle(item.Content)" v-html="item.Content"></div>
        </div>
        <div class="header" v-if="item.SenderID == userId">
          <img :src="logoUrl" alt="">
        </div>
      </div>
    </div>

    <div class="bottom-btn " :class="showKeyBoard ? 'show' : ''">
      <div class="flex-center-between input-line">
        <img :src="keyboardUrl" alt="" @click="showKeyBoard = !showKeyBoard">
        <div class="input flex-1">
          <input type="text" v-model="content" :placeholder="$t('message-placeholder')">
        </div>
        <div class="chat-btn">
          <Button @click="sendMsg">{{$t('fa-song')}}</Button>
        </div>
      </div>

      <div class="keyBoard-box flex-wrap" v-show="showKeyBoard">
        <div class="tag" v-for="(item, i) in tagList" :key="i" :class="i == 6 ? 'red' : ''" @click="chooseKeyboard(item)">{{item}}</div>
      </div>
    </div>

    <div class="msg-btn flex-center" :class="showKeyBoard ? 'top' : ''" v-show="showTag" @click="toBottom">
      <Icon name="arrow-down" color="#19be6b" size="14"></Icon>
      {{messageCount}}条新消息
    </div>
    <!-- all props & events -->
    <vue-easy-lightbox
        escDisabled
        moveDisabled
        :visible="visible"
        :imgs="imgs"
        :index="index"
        @hide="handleHide"
    ></vue-easy-lightbox>
  </div>
</template>
<script>
import { Button, Icon } from 'vant'
import topBar from '@/components/topBar'
import {initWebSocket} from "@/utils/common";
import { getUserId, getToken } from '@/utils/auth'
import { messageList, messageRead } from '@/api/shop'
import VueEasyLightbox from 'vue-easy-lightbox'
import store from "@/store";
export default {
  components: {
    Button,
    topBar,
    Icon,
    VueEasyLightbox
  },
  data() {
    return {
      uid: '',
      title: '',
      content: '',
      kefuUrl: require('../../assets/imgs/kefu.jpg'),
      keyboardUrl: require('../../assets/imgs/icon-keyborad.png'),
      logoUrl: require('../../assets/imgs/logo.png'),
      messageArr: [],
      client: null,
      Stomp: null,
      webSocket: null,
      showKeyBoard: false,
      messageCount: 0,
      showTag: false,
      page: {
        page: 1
      },
      tagList: ['1', '2', '3', '4', '5', '6', 'x', '=', '/', '0', '7', '8', '9', '取消', '番', '角', '严', '堂', '无', '查', '上', '大', '小', '单', '双', '__', '流水', '下', ],
      imgs: '',  // Img Url , string or Array
      visible: false,
      index: 0   // default: 0
    }
  },
  computed: {
    userId() {
      return getUserId()
    }
  },
  beforeDestroy() {
    if (this.webSocket && this.webSocket.close) {
      this.webSocket.close()
    }
  },
  mounted() {
    this.uid = this.$route.query.uid
    this.title = this.$route.query.name
    this.initMessage()
    if (this.userId) {
      this.initSocket()
    }

  },
  methods: {
    showSingle(content) {
      // 使用 DOMParser 解析 content 字符串
      const parser = new DOMParser();
      const doc = parser.parseFromString(content, 'text/html');

      // 获取 img 标签
      const imgElement = doc.querySelector('img');
      if (imgElement) {
        // 获取 img 的 src 属性值
        const imgSrc = imgElement.src;
        this.imgs = imgSrc
        // 或者传递一个图片配置对象
        // this.imgs = { title: 'this is a placeholder', src: 'http://via.placeholder.com/350x150' }
        this.show()
      }

    },
    show() {
      this.visible = true
    },
    handleHide() {
      this.visible = false
    },
    initMessage() {
      return
      let form = new FormData()
      form.append('current', this.page.page)
      form.append('pageSize', 120)
      form.append('ConversationID', this.uid)
      messageList(form).then(res => {
        let list = res.data.Items.reverse()
        this.messageArr = list
        setTimeout(() => {
          this.toBottom()
        }, 500)

        this.initRead()
      })
    },
    initRead() {
      let ids = this.messageArr.filter(v => {
        return !v.IsRead && v.ReceiverID == this.userId
      }).map(v => {
        return v.ID
      })
      if (ids.length > 0) {
        // messageRead({
        //   MessageID: ids
        // })
      }
    },
    async initSocket(){ //初始化weosocket
      await initWebSocket(this.userId, this.websocketonmessage)
      // store.state.client = store.state.client
      // this.Stomp = Stomp
      // this.webSocket = client.ws
    },
    back() {
      this.$router.go(-1)
    },
    websocketonmessage(e) {
      let redata = {}
      try {
        redata = JSON.parse(e.body)
        this.setListData(redata)
      }
      catch(e) {
      }
    },
    setListData(data) {
      // console.log(12345, data)
      let senderID = data.SenderID
      if (senderID === undefined) {
        return
      }
      // 历史记录
      if (data.Type == 6) {
        let tmp = JSON.parse(data.Content)
        console.log('tmp', tmp)
        // 检查是否有重复的消息
        // this.messageArr = tmp
        this.messageArr = []
        for(var i in tmp) {
          this.messageArr.unshift(tmp[i])
        }
      } else {
        this.messageArr.push(data)

      }
      // let ids = [data.ID]
      // messageRead({
      //   MessageID: ids
      // })
      let boxHeight = document.getElementById('messageBox').offsetHeight
      let scrollTop = window.pageYOffset
      let innerHeight = window.innerHeight
      if (boxHeight - innerHeight - scrollTop > 100) {
        this.messageCount += 1
        this.showTag = true
      } else {
        this.showTag = false
        this.messageCount = 0
        setTimeout(() => {
          this.toBottom()
        }, 500)
      }
    },
    sendMsg() {
      if (!this.content) {
        this.$toast.fail(this.$t('fa-song-nei-rong-bu-neng-wei-kong'))
        return
      }
      // 发送消息
      let message = {
        ReceiverID: parseInt(this.uid),
        Type: 0,
        Content: this.content,
        token: getToken()
      }
      if(store.state.client.connected == false) {
        this.initSocket();
        // 设置定时器，每隔一段时间检测连接状态
        let interval = setInterval(() => {
          if (store.state.client.connected) {
            clearInterval(interval); // 清除定时器
            store.state.client.send("/exchange/direct_logs/chat_queue", { "content-type": "application/json" }, JSON.stringify(message));
            this.content = '';
            this.showKeyBoard = false;
            this.toBottom();
          } else {
            this.initSocket();
            // this.initMessage();
          }
        }, 2000); // 每隔1秒检测一次连接状态
      } else {
        store.state.client.send("/exchange/direct_logs/chat_queue", { "content-type": "application/json" }, JSON.stringify(message));
        this.content = '';
        this.showKeyBoard = false;
        this.toBottom();
      }
    },
    chooseKeyboard(key) {
      if (key == 'x') {//删除
        if (this.content.length > 0) {
          this.content = this.content.substring(0, this.content.length - 1)
        }
      } else if (key == '__') {
        this.content += ' '
      } else {
        this.content += key
      }
    },
    toBottom() {
      window.scrollTo({top: 100000})
      this.showTag  = false
    }

  }
}
</script>